import React, { useEffect, useState } from "react";
import axios from "axios";
import '../styles/Consulta.css';
import '../styles/Home.css'; 
import { Consulta } from "./consulta";
import Carousel from 'react-bootstrap/Carousel';
import Image from 'react-bootstrap/Image';
import cr1 from '../img/banners1.jpg'; 
import cr2 from '../img/banners2.jpg';
import cr3 from '../img/banners3.jpg';
import cr4 from '../img/banners4.jpg';
import cr5 from '../img/banner_horario.jpg';
import crRendicion from '../img/bannerRendicion.jpeg';
import puntoPago1 from '../img/puntoPago1.png';
import puntoPago2 from '../img/puntoPago2.png'; 
import wu from '../img/wu.png';
import fa from '../img/facilito.png';
import sp from '../img/servipago.png';
import pb from '../img/produbanco.png';
import gb from '../img/bguayaquil.png';
import pab from '../img/bpacifico.png';
import pib from '../img/bpichincha.png';
import 'bootstrap/dist/css/bootstrap.min.css';
import { InstagramEmbed, TwitterEmbed } from "react-social-media-embed";
import MyMap from "./map";
import MyMapMovil from "./mapMovil";
import { NoticiasHome } from "./acordionNoticias";
import { Typography } from "@mui/material";
import { Flyer } from "./flyers";
import { Desplazo } from "./desplazo";
import { Link } from "react-router-dom";

interface Redes {
    id: number;
    instagram: string;
    twiter: string;
}

interface CustomIframeProps extends React.HTMLProps<HTMLIFrameElement> {
    gestureHandling?: string;
    loading?: "lazy";
  }
  const iframeProps: CustomIframeProps = {
    src: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15947.83265374942!2d-79.85893135270751!3d-2.1696113649776634!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x902d6c3d1a53ffff%3A0x1c556f5038eacaa6!2sEMAPAD-EP!5e0!3m2!1ses-419!2sec!4v1702561177867!5m2!1ses-419!2sec",
    width: "100%",
    height: "450",
    style: { border: '0', borderRadius: '10px', marginTop: '8px', marginBottom: '26px' },
    loading: "lazy",
    gestureHandling: "none", // Tu propiedad personalizada
  };


export const Home: React.FC<{}> = () => {
    const [ulrRedes, setUrl] = useState<Redes[]>([]);

    useEffect(() => {
        const fetchUrl = async () => {
            try {
                const response = await axios.get('https://www.emapad.gob.ec/app/conexion/apiRedes/');
                setUrl(response.data);
            } catch (error) {
                console.error("Error al obtener las marcaciones:", error);
            }
        };

        fetchUrl();
    }, []);
    const pagos="/home/PuntosdePago"

    const handleClick = () => {
        window.location.href = 'https://www.emapad.gob.ec/home/Horarios';
      };
    
    return(
        <div>
        <div className="consulta-container">
         <Carousel style={{marginTop:'30px'}}>
            <Carousel.Item interval={1500}>
                <Image src={cr1} rounded className="carouselImg" />
                <Carousel.Caption>
                {/*<h3>First slide label</h3>
                <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>*/}
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item interval={1500}>
                <Image src={cr2} rounded className="carouselImg"   />
                <Carousel.Caption>
                {/*<h3>Second slide label</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>*/}
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item interval={1500}>
                <Image src={cr3} rounded className="carouselImg"   />
                <Carousel.Caption>
                {/*<h3>Second slide label</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>*/}
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item interval={1500}>
                <Image src={cr4} rounded className="carouselImg"   />
                <Carousel.Caption>
                {/*<h3>Second slide label</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>*/}
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item interval={10500}>
                <div style={{ position: 'relative' }}>
                <Image src={cr5} rounded className="carouselImg" useMap="#image-map" />
                <map name="image-map">
                    <area 
                    shape="rect" 
                    coords="567,226,914,274" 
                    alt="Ingresa Aquí" 
                    onClick={handleClick} 
                    style={{ cursor: 'pointer' }}
                    />
                </map>
                </div>
                <Carousel.Caption>
                {/*<h3>Second slide label</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>*/}
                </Carousel.Caption>
            </Carousel.Item>
            
            </Carousel>
        
        </div>
        <Consulta/>  
        <div className="consulta-container-redes">
            <div className="red1"  >
            {ulrRedes.length > 0 && ulrRedes[0].instagram ? (
                <InstagramEmbed url={ulrRedes[0].instagram} width={'100%'}  />
            ) : (
                <p>Instagram no disponible</p>
            )}
            </div>
            <div className="red2" >
            {ulrRedes.length > 0 && ulrRedes[0].twiter ? (
                <TwitterEmbed url={ulrRedes[0].twiter} width={'100%'}  />
            ) : (
                <p>Twitter no disponible</p>
            )}
            </div>
        </div>
        <div className="consulta-container">
        <NoticiasHome/>
        </div>
        
        <div className="consulta-container">
        <div style={{display:"flex",flexDirection:"column",alignItems:"center",marginTop:"30px"}}>
            <Typography sx={{fontWeight:'bold',color:'#4F5051'}} variant="h5" gutterBottom>
                PUNTOS DE PAGO
        </Typography>
        </div>
        
            <div className="puntosPagos">
                
                <div className="card" data-name="Facilito">
                    <img src={fa} alt="pp2" />
                    <div className="card-body" style={{color:'white'}}>
                    <Link to={pagos} style={{textDecoration:'none',color:'white'}}>
                        <p className="card-title">Facilito</p>
                        </Link>
                    </div>
                </div>
                <div className="card" data-name="Servipago">
                    <img src={sp} alt="pp3" />
                    <div className="card-body" style={{color:'white'}}>
                    <Link to={pagos} style={{textDecoration:'none',color:'white'}}>
                        <p className="card-title">Servipago</p>
                        </Link>
                    </div>
                </div>
                <div className="card" data-name="Produbanco">
                    <img src={pb} alt="pp4" />
                    <div className="card-body" style={{color:'white'}}>
                    <Link to={pagos} style={{textDecoration:'none',color:'white'}}>
                        <p className="card-title">Produbanco</p>
                        </Link>
                    </div>
                </div >
                <div className="card" data-name="Banco Guayaquil">
                    <img src={gb} alt="pp5" />
                    <div className="card-body" style={{color:'white'}}>
                        <Link to={pagos} style={{textDecoration:'none',color:'white'}}>
                        <p className="card-title">Banco Guayaquil</p>
                        </Link>
                    </div>
                </div>
                <div className="card" data-name="Banco del Pacifico">
                    <img src={pab} alt="pp6"  />
                    <div className="card-body" style={{color:'white'}}>
                    <Link to={pagos} style={{textDecoration:'none',color:'white'}}>
                        <p className="card-title">Banco del Pacifico</p>
                        </Link>
                    </div>
                </div>
                <div className="card" data-name="Banco del Pichincha">
                    <img src={pib} alt="pp7"  />
                    <div className="card-body" style={{color:'white'}}>
                    <Link to={pagos} style={{textDecoration:'none',color:'white'}}>
                        <p className="card-title">Banco del Pichincha</p>
                        </Link>
                    </div>
                </div>
                <div className="card" data-name="Banco del Pichincha">
                    <img src={wu} alt="pp7"  />
                    <div className="card-body" style={{color:'white'}}>
                    <Link to={pagos} style={{textDecoration:'none',color:'white'}}>
                        <p className="card-title">Wester Union</p>
                        </Link>
                    </div>
                </div>
            </div>
            
           {/* <div className="mapDesktop">
                <MyMap />    
            </div>
            <div className="mapMovil">
                <MyMapMovil/>
            </div>*/}
            <div style={{display:"flex",flexDirection:"column",alignItems:"center",marginTop:"30px"}}>
            <Typography sx={{fontWeight:'bold',color:'#4F5051'}} variant="h5" gutterBottom>
                INFORMATIVOS
            </Typography>
        </div>
            <Flyer/>
            
            <div style={{display:"flex",flexDirection:"column",alignItems:"center",marginTop:"30px"}}>
                <Typography sx={{fontWeight:'bold',color:'#4F5051'}} variant="h5" gutterBottom>
                    MAPA
                </Typography>
             </div>
            <iframe {...iframeProps} />

          
            


        </div>
        </div>
    );





}