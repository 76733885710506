import React, { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { Alert, Button, Typography } from "@mui/material";
import axios from "axios";
import { SpinnerDotted } from 'spinners-react';
import emapadImage from '../img/emapad.png'; 
import id from '../img/identidad.png'; 
import '../styles/Consulta.css'; 
import { cliente } from "../interface/Cliente";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import logo from "../img/emapad.png"



const currencies = [
    {
      value: 'Cedula',
      label: 'Cedula',
      maxLength: 10,
    },
    {
      value: 'Numero de Cuenta',
      label: 'Cuenta',
      maxLength: 7,
    },
  ];


  


  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#2885E1',
      color: theme.palette.common.white,
      fontSize: 12,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 12,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  

export const Consulta: React.FC<{}> = () => {

    const [formData, setFormData] = useState({
        "in1": "",
        "tipoBusqueda": "",
    })
    const [cliente, setCliente] = useState<cliente>({
        "SERVICIO": "",
        "NOMBRES": "",
        "DIRECCION": "",
        "EMAIL": "",
        "CEDULA": "",
        "FECHA_PAGO": "",
        "PLANILLAS": "",
        "SALDO_ANTERIOR": "",
        "CONSUMO_MES": "",
        "REMISION": "",
        "TOTAL": ""
      });
    let valor = useState("")
    const [showSpinner, setShowSpinner] = useState(false);
    const [carga, setCarga] = useState(false);

    
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setFormData({
          ...formData,
          [name]: value,
        });
        console.log(formData)
      }
      const handleChangeOptions = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setFormData({
          ...formData,
          [name]: value,
          in1:"",
        });
        setCarga(false);
      }

    //funcion que trae ka informacion 
    const handleButton = async () => {
        if (formData.in1 !== "") {
            setShowSpinner(true);
            
    
            try {
                const response = await axios.get('http://186.5.117.203/clientesC/?id2=' + formData.in1);
                if(response.data !=null){
                    setCliente(response.data)
                }else{
                    window.alert('Usuario no encontrado');
                }
                

            } catch (error) {
                console.error("Error fetching data:", error);
            }

              setCarga(true); 
              setShowSpinner(false); 
            

        } else {
            window.alert('Ingrese una Cedula o Cuenta');
        }
       
    };
    const handleRedireccionar = () => {
        // Cambia 'https://www.ejemplo.com' por la URL externa a la que deseas redirigir
        window.open("http://186.5.117.203/Manager/usuario.php?planilla=" + cliente.SERVICIO, '_blank');
        
      };
      const handleRedireccionar2 = () => {
        // Cambia 'https://www.ejemplo.com' por la URL externa a la que deseas redirigir
        window.open("https://pagos.facilito.com.ec/aplicacion/duran/producto/EMAPAD-EP%20-%20DURAN%20CONSUMO", '_blank');
        
      };
    
    return(
      
      <div className="consulta-container divConsulta" >
            
        <Box className="consulta-title">
       
            <Typography sx={{fontWeight:'bold',color:'#4F5051'}} variant="h5" gutterBottom>
                    CONSULTA PLANILLAS EMAPAD-EP
            </Typography>
        </Box>

        <Box component="form" className="consulta-form">
        
       <br/>
            
            <TextField
                id="outlined-select-currency"
                select
                label="Tipo de Busqueda"
                defaultValue=""
                size="small"
                className="TextField"
                name="tipoBusqueda" 
                value={formData.tipoBusqueda} 
                onChange={handleChangeOptions}
                
                >
                {currencies.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                    {option.label}
                    </MenuItem>
                ))}
            </TextField>
            <TextField 
                id="outlined-basic" 
                label="Valor" 
                variant="outlined"  
                size="small" 
                name="in1" 
                value={formData.in1} 
                className="TextField"
                onChange={handleChange}
                inputProps={{ maxLength: currencies.find((c) => c.value === formData.tipoBusqueda)?.maxLength }}
                />
                
            <Button variant="contained" className="TextField" onClick={handleButton}>Buscar</Button>
            
        </Box>
        <div style={{ marginTop: '16px',  }}>
            {carga && (
                <div>
                <div className="cartas">
                <Card sx={{ maxWidth: 345 }} className="CartaCedula">
                <CardActionArea>
                  <CardMedia
                    component="img"
                    height="140"
                    image="http://www.jybaro.com/wp-content/uploads/2017/01/cedula-registro-civil.jpg"
                    alt="green iguana"
                  />
                  <CardContent >
                    <Typography gutterBottom variant="h5" component="div" align="center">
                      CED - {cliente.CEDULA}
                    </Typography>
                    <Typography variant="body2"  align="center">
                      {cliente.NOMBRES}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
              <Card className="cardPlanilla" >
                <CardActionArea>
                  <CardMedia
                    component="img"
                    height="75"
                    image={id}
                    alt="green iguana"
                  />
                  <CardContent className="contenidoCarta">
                  <Typography variant="body2"  align="center" sx={{fontWeight:'bold'}}>
                      DIRECCIÓN: {cliente.DIRECCION}
                    </Typography>
                    <div className="tablaDatos">
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 550 }} aria-label="customized table">
                        <TableHead>
                        <TableRow>
                            <StyledTableCell>CÓDIGO</StyledTableCell>
                            <StyledTableCell align="center">FECHA DE PAGO</StyledTableCell>
                            <StyledTableCell align="center">PLANILLAS IMPAGAS</StyledTableCell>
                            <StyledTableCell align="center">SALDO</StyledTableCell>
                            <StyledTableCell align="center">CONSUMO DEL MES</StyledTableCell>
                            <StyledTableCell align="center">TOTAL A PAGAR</StyledTableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        
                            <StyledTableRow >
                            <StyledTableCell component="th" scope="row">{cliente.SERVICIO}</StyledTableCell>
                            <StyledTableCell align="center">{cliente.FECHA_PAGO}</StyledTableCell>
                            <StyledTableCell align="center">{cliente.PLANILLAS}</StyledTableCell>
                            <StyledTableCell align="center">{cliente.SALDO_ANTERIOR}</StyledTableCell>
                            <StyledTableCell align="center">{cliente.CONSUMO_MES}</StyledTableCell>
                            <StyledTableCell align="center">{cliente.TOTAL}</StyledTableCell>
                            </StyledTableRow>
                        
                        </TableBody>
                    </Table>
                    </TableContainer>
                    </div>
                    <div className="celularDatos">
                    <Typography gutterBottom variant="body2" component="div" align="center">Código - {cliente.SERVICIO}</Typography>
                    <Typography gutterBottom variant="body2" component="div" align="center">Fecha de pago: {cliente.FECHA_PAGO}</Typography>
                    <Typography gutterBottom variant="body2" component="div" align="center">Planillas impagas: {cliente.PLANILLAS}</Typography>
                    <Typography gutterBottom variant="body2" component="div" align="center">Saldo: {cliente.SALDO_ANTERIOR}</Typography>
                    <Typography gutterBottom variant="body2" component="div" align="center">Consumo del mes: {cliente.CONSUMO_MES}</Typography>
                    <Typography gutterBottom variant="body2" component="div" align="center">Total a pagar {cliente.TOTAL}</Typography>
                    </div>

                  </CardContent>
                </CardActionArea>
              </Card>
              
              </div>
              <div style={{display:'flex', flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                <Button onClick={handleRedireccionar} variant="contained" sx={{marginTop:'8px', marginBottom:'16px'}}>Descarga Tu Planilla</Button>
                <Button onClick={handleRedireccionar2} variant="contained" sx={{marginTop:'8px', marginBottom:'16px', marginLeft:'8px'}}>Boton de Pago</Button>
              </div>
              
              </div>

            )}
        </div>
        {showSpinner && (
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    background: 'rgba(0, 0, 0, 0.5)',
                    zIndex: 999
                  }}>
                    <SpinnerDotted size={89} thickness={180} speed={127} color="rgba(0, 245, 234)"/>
                    <br />
                    <h1 style={{color:'white'}}>Consultando</h1>
                </div>
            )}
        
        </div>
    );





}