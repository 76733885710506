import React, { useEffect, useState } from "react";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import '../styles/Consulta.css'; 
import axios from "axios";
import { añosInterface } from "../interface/Años";
import { Button, List, ListItem, ListItemText, styled } from "@mui/material";
import { mesesInterface } from "../interface/MesesInterface";
import logo from "../img/emapad.png"


function generate(element: React.ReactElement) {
    return [0, 1, 2].map((value) =>
      React.cloneElement(element, {
        key: value,
      }),
    );
  }

  
  const Demo = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
  }));
  

export const Transparencia: React.FC<{}> = () => {

  interface MesesData {
    [mes: string]: {
      [numeral: string]: string; // Puedes ajustar el tipo de valor según lo que necesites almacenar
    };
  }

  const [datos, setDatos] = useState<MesesData>({});

    const [dense, setDense] = React.useState(false);
    const [secondary, setSecondary] = React.useState(false);
    const meses = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
    const numerales = ['1.1 ESTRUCTURA ORGANICA', 
                        '1.2-1.3 BASE LEGAL REGULACIONES PROCEDIMIENTOS INTERNOS', 
                        '1.4 METAS Y OBJETIVOS UNIDADES',  
                        '2.1-2.2 DIRECTORIO Y DISTRIBUTIVO PERSONAL DE LA ENTIDAD',
                        '3. REMUNERACIONES INGRESOS ADICIONALES',
                        '4. DETALLE LICENCIA COMISONES',
                        '5-22 SERVICIOS-FORMULARIOS-FORMATOS-TRÁMITES',
                        '6. PRESUPUESTO DE LA INSTITUCION',
                        '7. RESULTADOS DE LAS AUDITORIAS INTERNAS Y GUBERNAMENTALES', 
                        '8. PROCESOS DE CONTRATACION PUBLICA', 
                        '9. LISTADO DE EMPRESAS Y PERSONAS QUE HAN INCUMPLIDO CONTRATOS', 
                        '10. PLANES Y PROGRAMAS',
                        '11.CONTRATOS DE CREDITO EXTERNOS O INTERNOS',
                        '12. MECANISMOS RENDICION CUENTAS',
                        '13. VIATICOS INFORMES DE TRABAJO Y JUSTIFICATIVOS DE MOVILIZACION',
                        '14. RESPONSABLES DEL ACCESO DE INFORMACION PUBLICA',
                        '15. TEXTO INTEGRO DE LOS CONTRATOS COLECTIVOS VIGENTES Y REFORMAS',
                        '16. LISTADO INDICE FORMACION RESERVADA',
                        '17. DETALLE DE LAS AUDIENCIAS Y REUNIONES DE AUTORIDADES',
                        '18. DETALLE DE CONVENIOS NACIONALES E INTERNACIONALES',
                        '19. DETALLE DONATIVOS OFICIALES Y PROTOCOLARES',
                        '20. REGISTRO DE ACTIVOS DE INFORMACION FRECUENTE Y COMPLEMENTARIA',
                        '21. POLITICAS PUBLICAS O INFORMACION GRUPO ESPECIFICO',
                        '23. DETALLE PERSONAS SERVIDORAS PUBLICAS CON ACCIONES AFIRMATIVAS',
                        '24. INFORMACION RELEVANTE PARA EL EJECICIO DE DERECHOS ODS',
                        'TRANSPARENCIA ACTIVA',];
      const numeralesNew = ['TRANSPARENCIA ACTIVA'];
    const [añosa, setAñosa] = useState<añosInterface>()
    let [infoMes, setInfoMes] = useState<añosInterface>()
    const [Acordion, setAcordion] = React.useState(false);
    const [openPanel, setOpenPanel] = useState<string | null>(null);
    const nuevoLiteralesMes: mesesInterface = {
        "Enero": [''],
        "Febrero": [''],
        "Marzo": [''],
        "Abril": [''],
        "Mayo": [''],
        "Junio": [''],
        "Julio": [''],
        "Agosto": [''],
        "Septiembre": [''],
        "Octubre": [''],
        "Noviembre": [''],
        "Diciembre": [''],
        
      };
    const [literalesMes,setLiteralesMes] = useState<mesesInterface>({
        "Enero": [''],
        "Febrero": [''],
        "Marzo": [''],
        "Abril": [''],
        "Mayo": [''],
        "Junio": [''],
        "Julio": [''],
        "Agosto": [''],
        "Septiembre": [''],
        "Octubre": [''],
        "Noviembre": [''],
        "Diciembre": [''],
      });
    const [añoSeleccionado, setAñoSeleccionado] = useState<string | undefined>(undefined);

    useEffect(() => {
        const años = async () => {
        try {
            const response = await axios.get('http://www.emapad.gob.ec/app/lotaip/backend.php?carpeta=./LOTAIP/');
                //console.log(response.data)
                setAñosa(response.data)
               // console.log(añosa)
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }
    años();
      }, []);

      const [expanded, setExpanded] = React.useState<string | false>('panel1'); //expanded para expandir acoridon

      const handleChange =
          (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
          setExpanded(newExpanded ? panel : false);
          }; //funcion para expandir en base al panel 

      const handleClick = async (elemento: string) => {
        
        setExpanded(false);

        setAcordion(true)
      
        setAñoSeleccionado(elemento)
        if(parseInt(elemento) < 2024){ 
        const promises = meses.map(async (mes, index) => {
            let indice = meses.indexOf(mes) + 1;
            let mesString = indice + '.' + mes.toUpperCase();
        
            try {        
              const response = await axios.get(
                'http://www.emapad.gob.ec/app/lotaip/backend.php?carpeta=./LOTAIP/' +
                  elemento +
                  '/' +
                  mesString
              );
        
              return {
                mes: mes,
                documentos: response.data?.documentos || [],
              };
            } catch (error) {
              console.error('Error fetching data:', error);
              return {
                mes: mes,
                documentos: [],
              };
            }
          });
        
          try {
            // Esperar a que todas las promesas se completen
            const resultados = await Promise.all(promises);
        
            resultados.forEach((resultado) => {
                nuevoLiteralesMes[resultado.mes] = resultado.documentos;
              });
        
              setLiteralesMes((prevLiteralesMes) => ({
                ...prevLiteralesMes,
                ...nuevoLiteralesMes,
              }));
        
              
            } catch (error) {
              console.error('Error en Promise.all:', error);
            }
           // console.log(literalesMes);
          }
          else if(parseInt(elemento)>=2024){
            const nuevaData: MesesData = {};

            await Promise.all(/*
              meses.map(async (mes) => {
                nuevaData[mes] = {};
                let indice = meses.indexOf(mes) + 1;
                let mesString = indice + '.' + mes.toUpperCase();
        
                await Promise.all(
                  numerales.map(async (numeral) => {
                    const carpeta = encodeURIComponent( elemento + '/' + mesString + '/' + numeral);
                    //if(numeral=='TRANSPARENCIA ACTIVA'){

                    //}
                   // else{
                    try {
                     // console.log('http://www.emapad.gob.ec/app/lotaip/backend.php?carpeta=./LOTAIP/' +
                     // carpeta)
                      const response = await axios.get(
                        'https://www.emapad.gob.ec/app/lotaip/backend.php?carpeta=./LOTAIP/' + carpeta
                      );

                     // console.log(response.data)
        
                      nuevaData[mes][numeral] = response.data?.documentos || '';
                    } catch (error) {
                      console.error(`Error fetching data for ${mes} - ${numeral}:`, error);
                      nuevaData[mes][numeral] = ''; // Puedes manejar el error según tus necesidades
                    }
                  //}
                  })
                );
              })
                
            */
              meses.map(async (mes) => {
                nuevaData[mes] = {};
                let indice = meses.indexOf(mes) + 1;
                let mesString = indice + '.' + mes.toUpperCase();
                const numeralesToUse = mes === 'Enero' ? numerales : numeralesNew;

                await Promise.all(
                    numeralesToUse.map(async (numeral) => {
                        const carpeta = encodeURIComponent(elemento + '/' + mesString + '/' + numeral);

                        try {
                            const response = await axios.get(
                                'http://www.emapad.gob.ec/app/lotaip/backend.php?carpeta=./LOTAIP/' + carpeta
                            );

                            nuevaData[mes][numeral] = response.data?.documentos || '';
                        } catch (error) {
                            console.error(`Error fetching data for ${mes} - ${numeral}:`, error);
                            nuevaData[mes][numeral] = ''; // Puedes manejar el error según tus necesidades
                        }
                    })
                );
            })
            );
            setDatos(nuevaData);
            //console.log(datos)
          }
          };
        
          
        // Puedes agregar aquí la lógica que desees al hacer clic en el botón
        const abrirNuevaPagina = (mes:string,informacion:string,numeral:string) => {
          
            let indice = meses.indexOf(mes) + 1;
            let mesString = indice + '.' + mes.toUpperCase();
            let nuevaPaginaURL = ''
            if(añoSeleccionado && parseInt(añoSeleccionado) < 2024 ){
              // URL de la página que deseas abrir
            nuevaPaginaURL = 'http://www.emapad.gob.ec/app/lotaip/LOTAIP/'+añoSeleccionado+'/'+mesString+'/'+informacion;
            }
            else if(añoSeleccionado && parseInt(añoSeleccionado) >= 2024 ){
            nuevaPaginaURL = 'http://www.emapad.gob.ec/app/lotaip/LOTAIP/'+añoSeleccionado+'/'+mesString+'/'+numeral+'/'+informacion;
            }
            // Abre una nueva ventana o pestaña con la URL especificada
            window.open(nuevaPaginaURL, '_blank');
          };

          const capitalizeWords = (str: string): string => {
            return str.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
          };
          
      
    return(
        <div className="consulta-container" >
        
        <div className="consulta-title">
            <Typography sx={{fontWeight:'bold',color:'#4F5051'}} variant="h5" gutterBottom>
                TRANSPARENCIA
            </Typography>
        </div>
        <div style={{display:'flex',justifyContent:'center', marginBottom:'24px',alignItems:'center',flexWrap: 'wrap'}}>
            {añosa?.documentos.map((elemento, index) => (
                <Button variant="contained" sx={{margin:'4px'}} key={index} onClick={() => handleClick(elemento)} >
                {elemento}
                </Button>
            ))}
        </div>
        {Acordion && (
        <div className="consulta-title">
          <Typography sx={{fontWeight:'bold',color:'#4F5051'}} variant="h5" gutterBottom>
             {añoSeleccionado}
          </Typography>
        </div>
        )}
    {Acordion && (
        meses.map((mes, index) => (
        <Accordion key={index} expanded={expanded === mes} onChange={handleChange(mes)}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${index + 1}-content`}
            id={`panel${index + 1}-header`}
          >
            <Typography>{mes}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {añoSeleccionado &&  parseInt(añoSeleccionado) < 2024 ? (
              
              <Demo>
            <List >
            {literalesMes[mes].map((informacion, index) => (
                <ListItem sx={{borderColor:'#E8F8FD',borderWidth:'1px',borderStyle:'solid',borderRadius:'10px'}}>
                    <p onClick={() =>abrirNuevaPagina(mes,informacion,'nada')} style={{ cursor: 'pointer' }}>{informacion.slice(0,-4)}</p>
                </ListItem>
            ))}
            </List>
          </Demo>
            ):null }
            {añoSeleccionado &&  parseInt(añoSeleccionado) >= 2024 && datos[mes] ? (
            Object.entries(datos[mes]).map(([numeral, info], index) => (
              <Accordion key={index}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`panel${index + 1}-content`}
                  id={`panel${index + 1}-header`}
                >
                  <Typography sx={{ color: '#7A8081' }}>{capitalizeWords(numeral)}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Demo>
                    <List>
                      {Array.isArray(info) ? (
                        info.map((document, docIndex) => (
                          <ListItem key={docIndex} sx={{ borderColor: '#E8F8FD', borderWidth: '1px', borderStyle: 'solid', borderRadius: '10px' }}>
                            <p onClick={() => abrirNuevaPagina(mes, document,numeral)} style={{ cursor: 'pointer' }}>
                              {/*document.slice(0, -5)*/}
                              {document.endsWith('.xlsx') ? document.slice(0, -5) : document.endsWith('.pdf') ? document.slice(0, -4) : info}
                            </p>
                          </ListItem>
                        ))
                      ) : (
                        <ListItem key={0} sx={{ borderColor: '#E8F8FD', borderWidth: '1px', borderStyle: 'solid', borderRadius: '10px' }}>
                          <p onClick={() => abrirNuevaPagina(mes, info, numeral)} style={{ cursor: 'pointer' }}>
                            {info.endsWith('.xlsx') ? info.slice(0, -5) : info.endsWith('.pdf') ? info.slice(0, -4) : info}
                          </p>
                        </ListItem>
                      )}
                    </List>
                  </Demo>
                </AccordionDetails>
              </Accordion>
            ))
            
            
              
            ):null }
          </AccordionDetails>
        </Accordion>
         ))
         )}
        </div>
    );





}